import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import SubpageHero from "components/SubpageHero"
import Breadcrumbs from "components/Breadcrumbs"
import SubpageBack from "components/SubpageBack"

import { Content } from "page_components/competences"

const Competences = ({ data, location }) => {
  return (
    <Layout
      location={location}
      seo={{
        title: "Kompetencje",
      }}
    >
      <SubpageHero title="Kompetencje" />
      <Breadcrumbs title="Kompetencje" />
      <Content data={data?.allWpCompetence?.nodes} />
      <SubpageBack />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpCompetence(
      sort: { order: ASC, fields: date }
      filter: { language: { code: { eq: PL } } }
    ) {
      nodes {
        title
        slug
        id
      }
    }
  }
`

export default Competences
